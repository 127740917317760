import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

let Spots = ({}) => {
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let friends = useSelector(state => state.friends)

  return (
    <div style={local.row}>
      <div style={local.plus}>{`+`}</div>
      <div style={local.chart} />
    </div>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
    marginBottom: 80,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    alignSelf: 'stretch',
    marginBottom: 5,
    marginTop: 111,
  },
  plus: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 42,
    height: 42,
    marginRight: 4,
    border: '1px solid #DBD3D3',
    borderRadius: 6,
    margin: 9,
    color: '#ccc',
    fontSize: 18,
  },
  chart: {
    alignSelf: 'stretch',
    flex: 1,
    height: 58,
    marginLeft: 2,
    borderRadius: 6,
    border: '1px solid #DBD3D3',
  },
}

export default Spots
