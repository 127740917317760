import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import {getMe, setFlip} from '../redux/actions'
import Req from '../util/Req'

let At = ({}) => {
  let dispatch = useDispatch()
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let screenWidth = useSelector(state => state.screenWidth)

  let [name, setName] = useState('')
  let [loading, setLoading] = useState('')
  let [taken, setTaken] = useState(false)
  let [port, setPort] = useState([
    {symbol: 'btc', type: 'crypto', amt: '26'},
    {symbol: 'eth', type: 'crypto', amt: '24'},
    {symbol: 'aapl', type: 'stock', amt: '20'},
    {symbol: 'tsla', type: 'stock', amt: '30'},
  ])

  useEffect(() => {
    dispatch(setFlip(true))
    return () => {
      dispatch(setFlip(false))
    }
  }, [])

  let submit = async () => {
    let res = await Req.post(`${url}/at`, {uid: user.uid, at: name})
    if (res?.data) {
      dispatch(getMe())
    } else {
      console.log('taken')
    }
  }

  let pic = async () => {
    console.log('pic')
  }

  let done = async () => {
    let res = await Req.post(`${url}/port`, {uid: user.uid, port})
    if (res?.data) {
      dispatch(getMe())
    } else {
      console.log('taken')
    }
  }

  return (
    <div style={local.page}>
      <div style={{...local.banner, width: screenWidth}}>
        <div onClick={() => pic()} style={local.head}>
          <div style={local.inner}>
            <div style={{marginTop: 3, fontSize: 20, color: '#aaa'}}>{`+`}</div>
          </div>
        </div>

        <div
          className={'flexRow'}
          style={{
            position: 'absolute',
            bottom: -25,
            left: 0,
            width: screenWidth,
            justifyContent: 'flex-end',
          }}>
          <div className={'lora500'} style={{color: '#aaa'}}>
            {user?.at && (
              <div className={'flexRow'} style={{paddingRight: 5}}>
                <div
                  className={'lato300'}
                  style={{marginRight: 2, opacity: 0.5, fontSize: 15, marginBottom: -2, marginTop: 0.5}}>{`@`}</div>
                <div>{`${user?.at}`}</div>
              </div>
            )}
          </div>
        </div>

        {/* addr */}
        <div
          className={'flexRow'}
          style={{position: 'absolute', bottom: 4, right: 0, width: screenWidth, justifyContent: 'flex-end'}}>
          <div style={{color: '#FFF', paddingRight: 5}}>
            {user?.at && (
              <div className={'flexRow'}>
                <div>{`0x0000000`}</div>
              </div>
            )}
          </div>
        </div>
      </div>
      {!user?.at && (
        <>
          <div className={'flexRow'} style={{marginTop: 220}}>
            <div style={{...local.box, width: 92}}>{`@`}</div>
            <input
              autoFocus
              className={'lato300'}
              style={local.inputRowRight}
              value={name}
              onChange={e => {
                setTaken(false)
                setName(e.target.value)
              }}
              placeholder={'username'}
            />
          </div>
          {taken && <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 10, opacity: 0.6}}>{`taken`}</div>}
          {name?.length > 1 && !taken && (
            <div>
              {loading ? (
                <div style={local.submitBox}>
                  <div className={'arc-hider'} />
                </div>
              ) : (
                <div onClick={() => submit()} style={local.submitBox}>
                  create
                </div>
              )}
            </div>
          )}
        </>
      )}
      {user?.at && !user?.port && (
        <div className={'flexCol'} style={{marginTop: 235}}>
          <div style={local.portRow}>
            <div>btc</div>
            <div style={{...local.portPer, border: '0px solid #ddd', borderLeftWidth: 1}}>25%</div>
          </div>
          <div style={local.portRow}>
            <div>eth</div>
            <div style={{...local.portPer, border: '0px solid #ddd', borderLeftWidth: 1}}>105%</div>
          </div>
          <div style={local.portRow}>
            <div>aapl</div>
            <div style={{...local.portPer, border: '0px solid #ddd', borderLeftWidth: 1}}>5%</div>
          </div>
          <div style={local.portRow}>
            <div>tsla</div>
            <div style={{...local.portPer, border: '0px solid #ddd', borderLeftWidth: 1}}>25%</div>
          </div>
          <div style={{...local.portRow, justifyContent: 'center', fontSize: 20}}>
            <div style={{marginTop: 2}}>{`+`}</div>
          </div>
          <div onClick={() => done()} style={local.doneRow}>{`done`}</div>
        </div>
      )}
    </div>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
  },
  tile: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: 50,
    width: 50,
    borderRadius: 6,
    border: '1px solid #888',
    marginLeft: 6,
  },
  weekTile: {
    display: 'flex',
    flexDirection: 'column',
    height: 20,
    width: 50,
    borderRadius: 6,
    marginLeft: 6,
    alignItems: 'center',
  },
  invRow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    alignSelf: 'stretch',
    borderRadius: 6,
    border: '1px solid #888',
    marginBottom: 6,
    color: '#555',
    height: 42,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #ccc',
    alignSelf: 'baseline',
    height: 40,
    backgroundColor: '#ccc',
    color: '#fff',
  },
  inputRow: {
    backgroundColor: '#fbf7f5',
    border: '1px solid #ccc',
    borderRadius: 4,
    width: 200,
    height: 42,
    paddingLeft: 20,
    fontSize: 16,
  },
  inputRowRight: {
    backgroundColor: '#fbf7f5',
    border: '1px solid #ccc',
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 200,
    height: 42,
    paddingLeft: 20,
    fontSize: 16,
    marginLeft: -3,
    flex: 1,
  },
  submitBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    backgroundColor: '#38e1b7',
    color: '#fff',
    borderRadius: 4,
    height: 42,
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#e3e1df', //'#74604c', //'#e3e1df',
    height: 183,
    alignSelf: 'stretch',
    marginTop: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  head: {
    height: 150,
    width: 150,
    borderRadius: 75,
    backgroundColor: '#fbf7f5',
    marginBottom: -42,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    height: 130,
    width: 130,
    borderRadius: 65,
    border: '1px solid #ddd',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  portRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    borderRadius: 4,
    border: '1px solid #ccc',
    height: 42,
    paddingLeft: 11,
    paddingRight: 11,
    color: '#999',
    marginBottom: 6,
  },
  doneRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: 90,
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #ccc',
    height: 42,
    paddingLeft: 11,
    paddingRight: 11,
    color: '#999',
    marginBottom: 6,
  },
  portPer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 42,
    border: '1 px solid #ccc',
    width: 60,
  },
}

export default At
