import React, {Component} from 'react'
import {withRouter} from 'react-router-dom'
import {connect} from 'react-redux'
import {withCookies} from 'react-cookie'

import {Top, Sidebar, Modal, Nav} from './side'
import {getMe, getDevice, preloadImg} from './redux/actions'
import Main from './home/Main'

class Home extends Component {
  constructor(props) {
    super(props)
    let dir = this.props?.match?.params?.dir || ''
    let {cookies} = props
    let cookieArr = Object.values(cookies?.getAll() || {})
    this.props.set({dir, cookieArr})
    this.state = {}
    this.pageRef = React.createRef()
  }

  componentDidMount() {
    this.props.getDevice()
    if (process.env.NODE_ENV === 'development') {
      this.props.set({url: 'https://localhost:433'})
    }
    this.props.getMe()
    this.props.preloadImg()
  }

  preloadImgs = () => {}

  render() {
    let {screenHeight, showSideBar, modalShow} = this.props
    let pageBlur = showSideBar || modalShow ? 'blur' : ''

    return (
      <>
        <Sidebar />
        <div
          ref={this.pageRef}
          className={`${pageBlur} page lato300 hideScroll`}
          style={{
            height: screenHeight,
            overflow: 'scroll',
          }}>
          <Top />
          <Main pageRef={this.pageRef} />
          {false && <Nav />}
        </div>
        <Modal />
      </>
    )
  }
}

const reduxState = state => {
  return {
    network: state.network,
    screenHeight: state.screenHeight,
    showSideBar: state.showSideBar,
    solContract: state.solContract,
    address: state.address,
    modalShow: state.modalShow,
  }
}

const reduxDispatch = {
  set: value => ({type: 'set', payload: value}),
  getMe,
  getDevice,
  preloadImg,
}

export default withCookies(withRouter(connect(reduxState, reduxDispatch)(Home)))
