import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setFlip} from '../../redux/actions'
import Spring from './Spring'
import SpringRow from './SpringRow'

let Pro = ({setView}) => {
  let dispatch = useDispatch()

  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let friends = useSelector(state => state.friends)
  let flip = useSelector(state => state.flip)
  let screenWidth = useSelector(state => state.screenWidth)
  let [fade, setFade] = useState(0)
  let [add, setAdd] = useState(false)
  let [input, setInput] = useState('')
  let [num, setNum] = useState('')
  let numRef = useRef(null)
  let [active, setActive] = useState(null)

  let [port, setPort] = useState([
    {symbol: 'btc', type: 'crypto', amt: '26'},
    {symbol: 'eth', type: 'crypto', amt: '24'},
    {symbol: 'aapl', type: 'stock', amt: '20'},
    {symbol: 'tsla', type: 'stock', amt: '30'},
  ])

  useEffect(() => {
    dispatch(setFlip(true))
    return () => {
      dispatch(setFlip(false))
    }
  }, [])

  useEffect(() => {
    const timeoutId = setTimeout(() => setFade(1), 5)
    return () => clearTimeout(timeoutId)
  }, [])

  //when num input, - and % by rest
  let tryAdd = async () => {
    console.log('tryadd', input, num)
  }

  let onSwipe = symbol => {
    console.log('onSwipe', symbol)
  }

  let showRows = port?.map(a => {
    return (
      <SpringRow onSwipe={() => onSwipe(a.symbol)} key={a.symbol}>
        <div
          onClick={() => setActive(active == a.symbol ? null : a.symbol)}
          style={active == a.symbol ? local.portRowActive : local.portRow}>
          <div>{`${a.symbol}`}</div>
          <div style={{...local.portPer, border: '0px solid #ddd', borderLeftWidth: 1}}>{`${a.amt}%`}</div>
        </div>
      </SpringRow>
    )
  })

  return (
    <Spring goBack={() => setView(false)}>
      <div style={{...local.page, width: screenWidth}}>
        <div style={{...local.banner, width: screenWidth + 600}}>
          <div style={local.head}>
            <div style={{...local.inner, transition: 'opacity 500ms ease-in-out', opacity: 1}}></div>
          </div>
        </div>
        <div
          className={'flexCol'}
          style={{marginTop: 174, width: 330, transition: 'opacity 200ms ease-in-out', opacity: fade}}>
          {showRows}

          {!add && (
            <div onClick={() => setAdd(true)} style={{...local.portRow, justifyContent: 'center', fontSize: 20}}>
              <div style={{marginTop: 2}}>{`+`}</div>
            </div>
          )}
          {add && (
            <div style={local.portRow}>
              <input
                spellCheck={false}
                autoFocus
                className={'lato300 proInput'}
                style={local.input}
                value={input}
                onChange={e => {
                  setInput(e.target.value)
                }}
                placeholder={'xyz'}
                onBlur={() => numRef.current.focus()}
              />

              <div className={'flexRow'} style={{alignItems: 'center'}}>
                <input
                  ref={numRef}
                  spellCheck={false}
                  inputMode={'numeric'}
                  className={'lato300 proInput'}
                  style={local.inputNum}
                  value={num}
                  onChange={e => {
                    setNum(e.target.value)
                  }}
                  placeholder={'0'}
                  onBlur={() => tryAdd()}
                />
                <div style={{marginRight: -1, marginLeft: -1}}>{`%`}</div>
              </div>
            </div>
          )}
          {add && false && <div onClick={() => tryAdd()} style={local.doneRow}>{`done`}</div>}
        </div>
      </div>
    </Spring>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    //width: 330,
    alignSelf: 'center',
    alignItems: 'center',
    marginBottom: 80,
    paddingBottom: 80,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    alignSelf: 'stretch',
    marginBottom: 5,
  },
  photo: {
    width: 70,
    height: 70,
    marginLeft: 4,
    backgroundColor: '#e3e1df',
    borderRadius: 7,
  },
  chart: {
    alignSelf: 'stretch',
    flex: 1,
    height: 70,
    backgroundColor: '#e3e1df',
    borderRadius: 7,
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    backgroundColor: '#DBD3D3', //'#e3e1df', //'#74604c', //'#e3e1df',
    height: 128,
    alignSelf: 'stretch',
    marginTop: 0,
    position: 'absolute',
    top: 0,
    left: -300,
  },
  head: {
    height: 68,
    width: 68,
    borderRadius: 10,
    backgroundColor: '#fbf7f5',
    marginBottom: -28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: 318.5,
  },
  inner: {
    height: 60,
    width: 60,
    borderRadius: 6,
    backgroundColor: '#DBD3D3',
  },

  portRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    borderRadius: 4,
    border: '1px solid #ccc',
    height: 42,
    paddingLeft: 11,
    paddingRight: 11,
    color: '#999',
    marginBottom: 6,
    transition: 'width 0.3s ease-in-out',
    width: 300,
  },
  portRowActive: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'stretch',
    justifyContent: 'space-between',
    borderRadius: 4,
    border: '1px solid #ccc',
    height: 42,
    paddingLeft: 11,
    paddingRight: 11,
    color: '#FFF',
    marginBottom: 6,
    backgroundColor: '#ccc',
    transition: 'width 0.3s ease-in-out',
    width: 270,
  },
  doneRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    alignSelf: 'flex-end',
    width: 90,
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #ccc',
    height: 42,
    paddingLeft: 11,
    paddingRight: 11,
    color: '#999',
    marginBottom: 6,
  },
  portPer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 42,
    border: '1 px solid #ccc',
    width: 60,
  },
  input: {border: '0px solid #111', color: '#888', backgroundColor: '#fbf7f5', fontSize: 16},
  inputNum: {
    border: '0px solid #111',
    color: '#888',
    backgroundColor: '#fbf7f5',
    fontSize: 16,
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    height: 42,
    border: '1 px solid #ccc',
    width: 50,
    border: '0px solid #ddd',
    borderLeftWidth: 1,
    paddingLeft: 4,
    textAlign: 'right',
  },
}

export default Pro
