import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import SidebarX from './SidebarX'
import skull from '../imgs/skull.png'
import skullW from '../imgs/skullW.png'

let Menu = () => {
  let dispatch = useDispatch()
  let isMobile = useSelector(state => state.isMobile)
  let screenWidth = useSelector(state => state.screenWidth)
  let showSideBar = useSelector(state => state.showSideBar)
  let wallet = useSelector(state => state.wallet)
  let walletSol = useSelector(state => state.walletSol)
  let frontHalf = wallet?.obj?.address?.substring(0, 7) || null
  let frontHalfSol = walletSol?.obj?.publicKey?.toString()?.substring(0, 7) || null
  let flip = useSelector(state => state.flip)
  let loading = useSelector(state => state.loading)
  let [fade, setFade] = useState(0)

  let refresh = () => {
    const rootUrl = window.location.origin
    window.location.href = rootUrl
    //window.location.reload()
  }

  let walletClick = () => {
    console.log('wallet?.obj?.address', wallet?.obj?.address)
    console.log('walletSol?.obj?.publicKey?.toString()', walletSol?.obj?.publicKey?.toString())
  }

  useEffect(() => {
    if (!loading) {
      //fade
      const timeoutId = setTimeout(() => setFade(1), 5)
      return () => clearTimeout(timeoutId)
    }
  }, [loading])

  if (loading) return null

  return (
    <div className={'top'} style={{width: screenWidth, transition: 'opacity 600ms ease-in-out', opacity: fade}}>
      <div className={'topMid'} style={{width: isMobile ? 320 : 640}}>
        <div
          onClick={() => refresh()}
          className={'flexRow lato300'}
          style={{alignItems: 'center', marginBottom: isMobile ? 7 : 0, opacity: 0.3}}>
          <div style={{height: 30, width: 30, marginLeft: -4}}>
            <img src={flip ? skullW : skull} style={{height: 30, widht: 30}} />
          </div>
          <div style={{marginLeft: 4, marginBottom: 2, color: flip ? '#FFF' : '#555'}}>baps</div>
        </div>
        <div className={'flexRow'}>
          {frontHalfSol && (
            <div onClick={() => walletClick()} style={styles.walletBox}>
              {frontHalfSol}
            </div>
          )}
          {frontHalf && (
            <div onClick={() => walletClick()} style={styles.walletBox}>
              {frontHalf}
            </div>
          )}
          <div style={{marginBottom: isMobile ? 10 : 4, marginLeft: 6, marginRight: -6, opacity: 0.5}}>
            <SidebarX showSideBar={showSideBar} toggleSidebar={() => dispatch({type: 'toggleSidebar'})} flip={flip} />
          </div>
        </div>
      </div>
    </div>
  )
}
export default Menu

let styles = {
  page: {
    flex: 1,
    width: 330,
    alignSelf: 'center',
  },
  walletBox: {
    fontSize: 14,
    marginRight: 6,
    border: '1px solid #aaa',
    borderRadius: 4,
    padding: 3,
    paddingLeft: 5,
    paddingRight: 5,
    height: 18,
    color: '#555',
    marginTop: -1.5,
  },
}
