import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'

import Time from './time/Time'
import Pro from './pro/Pro'

import At from './At'

let Menu = ({pageRef}) => {
  let [view, setView] = useState(false)
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)

  let show = <Time setView={setView} pageRef={pageRef} />
  if (!user?.at || !user?.port) {
    show = <At />
  }
  if (view) {
    show = <Pro setView={setView} />
  }

  return (
    <div style={styles.page}>
      {tab == 'wallet' && <div>wallets</div>}
      {tab == 'home' && <>{show}</>}
    </div>
  )
}

let styles = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    //width: 330,
    alignSelf: 'center',
  },
}

export default Menu
