import React, {useCallback, useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import Req from '../util/Req'

import {last5} from '../util/helpers'
import xWhite from '../imgs/xWhite.png'
import plus from '../imgs/plus.png'

let Action = () => {
  let dispatch = useDispatch()
  let url = useSelector(state => state.url)
  let user = useSelector(state => state.user)
  let screenHeight = useSelector(state => state.screenHeight)
  let device = useSelector(state => state.device)

  let [num, setNum] = useState('')
  let [active, setActive] = useState(null)
  let [input, setInput] = useState('')

  useEffect(() => {
    activeLoad()
  }, [user])

  let activeLoad = () => {
    if (!active) {
      setActive(actions?.length > 0 ? actions[0]?.id : null)
    }
  }

  let add = async () => {
    //replace today
    /*
    console.log('user?.lifts', user?.lifts)
    return
    */

    let action = user?.actions?.filter(a => a.id == active)[0] || {id: 1, name: input}
    let res = await Req.post(`${url}/lift`, {uid: user.uid, num, liftId: action.id, name: action.name})
    console.log('add res', res)
    setNum('')
    let userUpdate = {...user, ...res.data?.user}
    dispatch({type: 'set', payload: {user: userUpdate, modalShow: false}})
  }

  let newLift = async () => {
    let id = user?.actions?.length + 1 || 1
    let action = {id, name: input}
    let res = await Req.post(`${url}/action`, {uid: user.uid, id: action.id, name: action.name})
    setInput('')
    setActive(id)
    console.log('res action', res.data.user)
    let userUpdate = {...user, ...res.data?.user}
    dispatch({type: 'set', payload: {user: userUpdate}})
  }

  let actions = user?.actions || []
  let showActions = actions?.map(a => {
    return (
      <div
        key={a.id}
        onClick={() => setActive(a.id)}
        style={{
          fontSize: 20,
          marginLeft: 14,
          marginBottom: 2,
          padding: 4,
          paddingLeft: 5,
          borderTopLeftRadius: 1,
          borderBottomLeftRadius: 1,
          backgroundColor: active == a.id ? '#ccc' : '#666',
          color: active == a.id ? '#555' : '#bbb',
        }}>
        {a.name}
      </div>
    )
  })

  let cont = num && active
  let fullScreenIos = device?.includes('iOS') && screenHeight > 700

  return (
    <div
      className={'flexCol'}
      style={{
        alignItems: 'center',
        paddingTop: 20,
        color: '#fff',
        fontSize: 18,
        height: 500,
        justifyContent: 'space-between',
      }}>
      <div className={'flexCol'} style={{alignItems: 'center'}}>
        <div className={'flexRow'} style={{marginTop: 20, alignItems: 'flex-start'}}>
          <input
            style={{
              height: 140,
              width: 140,
              borderRadius: 10,
              border: '1px solid #aaa',
              marginRight: 18,
              backgroundColor: '#666',
              color: '#eee',
              fontSize: 40,
              textAlign: 'center',
              alignSelf: 'stretch',
            }}
            className={'addText flexCenter lato300'}
            type="number"
            inputMode="numeric"
            value={num}
            onChange={e => setNum(e.target.value)}
            placeholder={'Ø'}
          />
          <div className={'flexCol'}>
            {actions?.length > 0 && (
              <div
                className={'flexCol hideScroll'}
                style={{
                  maxHeight: 308,
                  minHeight: 40,
                  width: 140,
                  borderRadius: 10,
                  border: '1px solid #aaa',
                  color: '#fff',
                  overflowY: 'scroll',
                  overflowX: 'hidden',
                  paddingTop: 14,
                  paddingBottom: 10,
                }}>
                {showActions}
              </div>
            )}
            <input
              className={'lato300 newInput'}
              style={{...styles.newInput, marginTop: actions?.length > 0 ? 10 : 0}}
              value={input}
              onChange={e => setInput(e.target.value)}
              placeholder={'+ new'}
              spellCheck={false}
            />
            {input?.length > 0 && (
              <div className={'flexRow'} style={{width: 142, justifyContent: 'flex-end', marginTop: 8}}>
                <div
                  onClick={() => newLift()}
                  className={'flexCenter'}
                  style={{height: 40, width: 40, borderRadius: 8, border: '1px solid #aaa'}}>
                  <img
                    src={plus}
                    style={{
                      height: 31,
                      width: 31,
                      opacity: 0.6,
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        onClick={() => add()}
        className={'flexCenter'}
        style={{
          height: 40,
          width: 300,
          borderRadius: 6,
          border: '1px solid #aaa',
          alignSelf: 'flex-end',
          marginBottom: fullScreenIos ? 50 : 20,
          backgroundColor: cont ? '#ccc' : '#666',
        }}>
        <div style={{color: cont ? '#555' : '#bbb'}}>{`add`}</div>
      </div>
    </div>
  )
}
export default Action

let styles = {
  activeAdd: {
    backgroundColor: '#bbb',
    color: '#333',
  },
  newInput: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#666',
    border: '1px solid #aaa',
    height: 42,
    width: 142,
    borderRadius: 10,
    alignItems: 'center',
    paddingLeft: 18,
    fontSize: 20,
    color: '#eee',
  },
}
