import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {useSpring, animated} from 'react-spring'
import {setSp, setFlip} from '../../redux/actions'

import Spots from './Spots'

let Time = ({setView, pageRef}) => {
  let dispatch = useDispatch()
  let [arr, setArr] = useState([])

  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let ppl = useSelector(state => state.ppl)
  let sp = useSelector(state => state.sp)
  let screenWidth = useSelector(state => state.screenWidth)
  let [ghost, setGhost] = useState({left: 0, top: 0, visible: false})

  let [fade, setFade] = useState(0)
  let [slab, setSlab] = useState(false)
  let [headOp, setHeadOp] = useState(0)

  useEffect(() => {
    console.log('sp && pageRef.current', sp, pageRef.current)
    if (arr && sp && pageRef.current) {
      pageRef.current.scrollTop = sp
    }
    if (arr) {
      setFade(1)
    }
  }, [arr])

  useEffect(() => {
    assemble()
  }, [ppl, user])

  useEffect(() => {
    console.log('new setView')
  }, [setView])

  let assemble = () => {
    let newArr = ppl?.concat([{at: user.at, port: user.port, img: user.img}])
    newArr = [
      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},
      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},
      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},
      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},

      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},
      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},

      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},
      {at: 'jamal', port: [], img: ''},
      {at: 'jamal2', port: [], img: ''},
    ]?.concat(newArr)
    setArr(newArr)
  }

  // ghost
  const ghostStyle = useSpring({
    from: {transform: `translateY(${-ghost.top + 92}px)`},
    to: {transform: `translateY(0px)`},
    reset: false,
    reverse: ghost.visible,
    //config: {duration: 400},
    config: {
      tension: 170, // Controls the speed and bounciness of the animation
      friction: 23, // Controls the rate of deceleration
      mass: 1, // Mass of the object being animated, affecting its inertia
    },
    //onRest: () => setGhost(prev => ({...prev, visible: true})), // Optionally hide after animation
  })

  let click = (e, at) => {
    const rect = e.currentTarget.getBoundingClientRect()
    console.log(`Position - x: ${rect.left}, y: ${rect.top}`)

    //save scroll
    const se = document.querySelector('.page')
    const sp = se.scrollTop
    console.log('sp', sp)
    dispatch(setSp(sp))

    //ghost
    setGhost({
      left: rect.left,
      top: rect.top,
      visible: true,
    })

    //fadeOut
    setFade(0)

    setTimeout(() => {
      setSlab(true)
      dispatch(setFlip(true))
    }, 50)

    setTimeout(() => {
      setHeadOp(1)
    }, 50) //250

    setTimeout(() => {
      setView(at)
    }, 450) //450
  }

  let showArr = arr?.map((a, k) => {
    return (
      <div key={k} style={local.row}>
        <div onClick={e => click(e, a.at)} style={local.photo} />
        <div style={local.chart}>
          <div style={{color: '#fff', position: 'absolute', bottom: 1.5, left: 67}}>{`${a.at}`}</div>
        </div>
        {a.num}
      </div>
    )
  })

  return (
    <div style={local.page}>
      {/*transition in slab */}
      <div
        className={'slab'}
        style={{
          ...local.slab,
          width: screenWidth,
          transform: slab ? 'translateY(158px)' : 'translateY(0px)', // Control vertical movement
          opacity: slab ? 1 : 0,
        }}>
        <div style={{...local.head, opacity: headOp ? 1 : 0, transition: 'opacity 300ms ease-in-out'}}>
          <div style={local.inner}></div>
        </div>
      </div>
      {/*ghost */}
      {ghost.visible && (
        <animated.div
          style={{
            position: 'absolute',
            left: `${ghost.left}px`,
            top: `${ghost.top}px`,
            width: '60px',
            height: '60px',
            backgroundColor: '#DBD3D3', // Adjust color as needed
            borderRadius: '6px',
            ...ghostStyle,
          }}>
          {/* Content of the new div */}
        </animated.div>
      )}

      <div style={{...local.pageInner, opacity: fade}}>
        <Spots />
        {showArr}
      </div>
    </div>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    alignItems: 'center',
    alignSelf: 'center',
    marginBottom: 80,
    transition: 'opacity 600ms ease-in-out',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 60,
    alignSelf: 'stretch',
    marginBottom: 5,
    position: 'relative',
  },
  photo: {
    width: 60,
    height: 60,
    marginRight: 4,
    backgroundColor: '#DBD3D3', //'#D4CBCB', //E3E1DF
    borderRadius: 6,
  },
  chart: {
    alignSelf: 'stretch',
    flex: 1,
    height: 60,
    backgroundColor: '#DBD3D3',
    borderRadius: 6,
  },
  slab: {
    transition: 'opacity 300ms ease-in-out, transform 300ms ease',
    height: 153,
    backgroundColor: '#DBD3D3',
    marginTop: -183,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
  },
  pageInner: {
    transition: 'opacity 200ms ease-in-out',
    width: 330,
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    backgroundColor: '#DBD3D3', //'#e3e1df', //'#74604c', //'#e3e1df',
    height: 133,
    alignSelf: 'stretch',
    marginTop: 0,
    position: 'absolute',
    top: 0,
    left: 0,
  },
  head: {
    height: 68,
    width: 68,
    borderRadius: 10,
    backgroundColor: '#fbf7f5',
    marginBottom: -28,
    display: 'flex',
    justifyContent: 'center',
    marginLeft: 18.5,
  },
  inner: {
    height: 60,
    width: 60,
    borderRadius: 14,
    //backgroundColor: '#d1c1f5',
  },
}

export default Time
