import React, {useState, useEffect, useRef} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {GoogleLogin, useGoogleLogin} from '@react-oauth/google'
import Req from '../util/Req'
import {getMe} from '../redux/actions'
import xPng from '../imgs/x.png'

let Auth = ({}) => {
  let dispatch = useDispatch()
  let [send, setSend] = useState(false)
  let url = useSelector(state => state.url)
  let device = useSelector(state => state.device)
  let [loading, setLoading] = useState(false)
  let [step, setStep] = useState(0)
  let [type, showType] = useState('phone')

  //email
  let [email, setEmail] = useState('')
  let sendEmail = async () => {
    setLoading(true)
    console.log('sendEmail', email)
    await Req.post(`${url}/email`, {email})
    setLoading(false)
    setStep(2)
  }

  let restart = () => {
    setStep(1)
    setEmail('')
    setPhone('')
    setCode('')
    showType(null)
    setLoading(false)
  }

  //phone
  let [phone, setPhone] = useState('')
  let [code, setCode] = useState('')
  let [uid, setUid] = useState(null)

  let checkSubmit = type => {
    let number = phone?.replaceAll('-', '')?.replace('+1', '')
    if (type == 'phone' && number?.length > 9) {
      submit()
    }
    if (type == 'code' && code?.length == 6) {
      submitCode()
    }
  }

  let submit = async () => {
    let number = phone?.replaceAll('-', '')?.replace('+1', '')
    setLoading(true)
    let res = await Req.post(`${url}/phone`, {number})
    setUid(res.data?.uid)
    setLoading(false)
    if (res) setStep(2)
  }

  let submitCode = async () => {
    setLoading(true)
    let res = await Req.post(`${url}/code`, {uid, code})
    let data = res?.data
    setLoading(false)
    if (data?.user?.uid) {
      if (data?.sessionID) {
        localStorage.setItem('y-sess', data?.sessionID)
        Req.update()
      }
      dispatch(getMe())
    }
  }

  return (
    <div className={'flexCol'} style={styles.page}>
      {step == 0 && (
        <div className={'flexCol'} style={{flex: 1, alignItems: 'center', justifyContent: 'space-between'}}>
          <div className={'flexCol'} style={{alignSelf: 'stretch', alignItems: 'center'}}>
            <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 40, opacity: 0.6}}>{`baps`}</div>
            <div style={{border: '0px solid #bbb', borderBottomWidth: 1, width: 120, marginTop: 10}} />
            <div
              style={{
                alignSelf: 'stretch',
                textAlign: 'center',
                marginTop: 10,
                opacity: 0.6,
              }}>{`track stock & crypto `}</div>
            <div style={{alignSelf: 'stretch', textAlign: 'center', marginTop: 6, opacity: 0.6}}>{`with friends`}</div>
          </div>
          <div style={{alignSelf: 'stretch'}}>
            <div
              onClick={() => setStep(1)}
              className={'flexCenter'}
              style={{
                alignSelf: 'stretch',
                height: 50,
                borderRadius: 6,
                color: '#fff',
                backgroundColor: '#38e1b7',
                marginBottom: 28,
              }}>
              authenticate
            </div>
          </div>
        </div>
      )}
      {step == 1 && (
        <>
          <div className={'flexRow'} style={{justifyContent: 'space-between', alignSelf: 'stretch', alignItems: 'center'}}>
            <div style={{...styles.box, width: 90}}>
              <div>auth</div>
            </div>
            {type && <img onClick={() => restart()} src={xPng} style={{height: 27, width: 27, opacity: 0.55}} />}
          </div>
          {type == 'phone' && (
            <div className={'flexRow'} style={{marginTop: 8}}>
              <div style={{...styles.box, width: 92}}>phone</div>
              <input
                autoFocus
                className={'lato300'}
                type="number"
                inputMode="numeric"
                style={styles.inputRowRight}
                value={phone}
                onChange={e => {
                  setStep(1)
                  setPhone(e.target.value)
                }}
                placeholder={'800-000-0000'}
                onBlur={() => checkSubmit('phone')}
              />
            </div>
          )}
          {type == 'email' && (
            <div className={'flexRow'} style={{marginTop: 8}}>
              <div style={{...styles.box, width: 92}}>email</div>
              <input
                autoFocus
                className={'lato300'}
                style={styles.inputRowRight}
                value={email}
                onChange={e => {
                  setStep(1)
                  setEmail(e.target.value)
                }}
                placeholder={'name@gmail.com'}
                onBlur={() => sendEmail()}
              />
            </div>
          )}
          {!type && false && (
            <>
              <div onClick={() => showType('phone')} style={styles.boxHollow}>
                phone #
              </div>
              {false && (
                <div onClick={() => showType('email')} style={styles.boxHollow}>
                  email
                </div>
              )}
            </>
          )}

          {step == 1 && phone?.length > 9 && (
            <div>
              {loading ? (
                <div style={styles.submitBox}>
                  <div className={'arc-hider'} />
                </div>
              ) : (
                <div onClick={() => submit()} style={styles.submitBox}>
                  submit
                </div>
              )}
            </div>
          )}
          {step == 1 && email?.includes('@') && (
            <div>
              {loading ? (
                <div style={styles.submitBox}>
                  <div className={'arc-hider'} />
                </div>
              ) : (
                <div onClick={() => sendEmail()} style={styles.submitBox}>
                  submit
                </div>
              )}
            </div>
          )}
          {phone?.length < 1 && false && (
            <>
              <div onClick={() => runGoogle()} style={styles.boxHollow}>
                google
              </div>
              {device?.includes('iOS') && (
                <div onClick={() => runApple()} style={styles.boxHollow}>
                  {`apple -soon-`}
                </div>
              )}
            </>
          )}
        </>
      )}
      {step == 2 && (
        <>
          <div className={'flexRow'} style={{justifyContent: 'space-between', alignSelf: 'stretch', alignItems: 'center'}}>
            <div style={{...styles.box, width: 90}}>
              <div>auth</div>
            </div>
            {type && <img onClick={() => restart()} src={xPng} style={{height: 27, width: 27, opacity: 0.55}} />}
          </div>
          <div className={'flexRow'} style={{marginTop: 8}}>
            <div style={{...styles.box, width: 92}}>code</div>
            <input
              autoFocus
              className={'lato300'}
              type="number"
              inputMode="numeric"
              style={styles.inputRowRight}
              value={code}
              onChange={e => setCode(e.target.value)}
              placeholder={'000000'}
              onBlur={() => checkSubmit('code')}
            />
          </div>
        </>
      )}
      {step == 2 && code?.length == 6 && (
        <div>
          {loading ? (
            <div style={styles.submitBox}>
              <div className={'arc-hider'} />
            </div>
          ) : (
            <div onClick={() => submitCode()} style={styles.submitBox}>
              submit
            </div>
          )}
        </div>
      )}
    </div>
  )
}

let styles = {
  page: {
    flex: 1,
    width: 330,
    alignSelf: 'center',
    marginTop: 80,
  },
  box: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #888',
    alignSelf: 'baseline',
    height: 40,
    backgroundColor: '#888',
    color: '#fff',
  },
  boxHollow: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 4,
    border: '1px solid #888',
    alignSelf: 'stretch',
    height: 40,
    color: '#555',
    marginTop: 8,
  },
  boxDivider: {
    color: '#fff',
    height: 25,
    border: '0px solid #ccc',
    marginLeft: 23,
    marginRight: 23,
    marginTop: 2,
    borderRightWidth: 1,
  },
  inputRow: {
    backgroundColor: '#fbf7f5',
    border: '1px solid #888',
    borderRadius: 4,
    width: 200,
    height: 42,
    paddingLeft: 20,
    fontSize: 16,
  },
  inputRowRight: {
    backgroundColor: '#fbf7f5',
    border: '1px solid #888',
    borderRadius: 4,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    width: 200,
    height: 42,
    paddingLeft: 20,
    fontSize: 16,
    marginLeft: -3,
    flex: 1,
  },
  submitBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 8,
    backgroundColor: '#38e1b7',
    color: '#fff',
    borderRadius: 4,
    height: 42,
  },
}

export default Auth
