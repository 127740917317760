import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import PullToRefresh from 'react-simple-pull-to-refresh'
import {useParams} from 'react-router-dom'

import {getMe} from '../redux/actions'
import Auth from './Auth'
import Install from './Install'
import Menu from './Menu'
import View from './pro/View'
import downPng from '../imgs/down.png'

let Main = ({pageRef}) => {
  let params = useParams()
  let dispatch = useDispatch()

  let device = useSelector(state => state.device)
  let isPwa = device?.includes('PWA')
  if (process.env.NODE_ENV === 'development') isPwa = true
  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let loading = useSelector(state => state.loading)
  let [fade, setFade] = useState(0)

  let handleRefresh = async () => {
    await dispatch(getMe())
  }

  //background
  useEffect(() => {
    const seeU = async () => {
      const state = document.visibilityState
      if (state === 'hidden') {
        //
      }
      if (state === 'visible') {
        await dispatch(getMe())
      }
    }
    document.addEventListener('visibilitychange', seeU)
    return () => {
      document.removeEventListener('visibilitychange', seeU)
    }
  }, [])

  useEffect(() => {
    //fade
    const timeoutId = setTimeout(() => setFade(1), 5)
    return () => clearTimeout(timeoutId)
  }, [])

  let page
  if (loading) {
    page = <div />
  } else if (!isPwa) {
    page = <Install />
  } else if (!user?.state && params.dir?.includes('@')) {
    page = <View />
  } else if (!user?.state) {
    page = <Auth />
  } else {
    page = (
      <PullToRefresh
        className="pull"
        resistance={2.5}
        onRefresh={handleRefresh}
        pullDownThreshold={40}
        refreshingContent={
          <div className={'flexRow'} style={{alignSelf: 'stretch', justifyContent: 'center', marginTop: 0, height: 24}}>
            <div className={'arc-hider'} />
          </div>
        }
        pullingContent={
          <div className={'flexRow'} style={{alignSelf: 'stretch', justifyContent: 'center', marginTop: 0, height: 24}}>
            <img src={downPng} style={{height: 20, width: 20, opacity: 0.2, marginLeft: 2}} />
          </div>
        }>
        <div className={'flexCol'} style={{alignSelf: 'stretch', alignItems: 'center'}}>
          <Menu pageRef={pageRef} />
        </div>
      </PullToRefresh>
    )
  }

  return (
    <div
      className={'mainList lato300'}
      style={{
        alignSelf: 'stretch',
        flex: 1,
      }}>
      {loading && (
        <div className={'flexRow'} style={{alignSelf: 'stretch', justifyContent: 'center', marginTop: 80, opacity: 0.5}}>
          <div className={'arc-hider'} />
        </div>
      )}
      {page}
    </div>
  )
}
export default Main
