import React, {useState, useEffect} from 'react'
import {useSelector, useDispatch} from 'react-redux'
import {setFlip} from '../../redux/actions'
import {useParams} from 'react-router-dom'
import Req from '../../util/Req'

let View = ({setView}) => {
  let params = useParams()
  let dispatch = useDispatch()

  let user = useSelector(state => state.user)
  let url = useSelector(state => state.url)
  let tab = useSelector(state => state.tab)
  let friends = useSelector(state => state.friends)
  let flip = useSelector(state => state.flip)
  let screenWidth = useSelector(state => state.screenWidth)
  let isMobile = useSelector(state => state.isMobile)

  useEffect(() => {
    getAt()
  }, [])

  useEffect(() => {
    dispatch(setFlip(true))
    return () => {
      dispatch(setFlip(false))
    }
  }, [])

  let getAt = async () => {
    let at = params.dir?.replace('@', '')
    let res = await Req.get(`${url}/at?at=${at}`)
    if (res.data) {
      console.log('data', res.data)
    } else {
      console.log('no acc')
    }
  }

  return (
    <div style={{...local.page}}>
      <div style={{...local.banner, width: screenWidth, marginLeft: (330 - screenWidth) / 2}}>
        <div style={local.head}>
          <div style={local.inner}></div>
        </div>
      </div>
      <div style={{...local.row, marginTop: 32}}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
      <div style={local.row}>
        <div style={local.chart} />
        <div style={local.photo} />
      </div>
    </div>
  )
}

let local = {
  page: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    width: 330,
    alignSelf: 'center',
    marginBottom: 80,
    paddingBottom: 80,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 70,
    alignSelf: 'stretch',
    marginBottom: 5,
  },
  photo: {
    width: 70,
    height: 70,
    marginLeft: 4,
    backgroundColor: '#e3e1df',
    borderRadius: 7,
  },
  chart: {
    alignSelf: 'stretch',
    flex: 1,
    height: 70,
    backgroundColor: '#e3e1df',
    borderRadius: 7,
  },
  banner: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    backgroundColor: '#e3e1df', //'#74604c', //'#e3e1df',
    height: 183,
    alignSelf: 'stretch',
    marginTop: 0,
  },
  head: {
    height: 150,
    width: 150,
    borderRadius: 75,
    backgroundColor: '#fbf7f5',
    marginBottom: -28,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  inner: {
    height: 130,
    width: 130,
    borderRadius: 65,
    backgroundColor: '#d1c1f5',
  },
}

export default View
