import React from 'react'
import {useSpring, animated} from 'react-spring'
import {useDrag} from 'react-use-gesture'

let Spring = ({children, goBack}) => {
  const [{x, opacity}, set] = useSpring(() => ({x: 0, opacity: 1}))

  const bind = useDrag(({down, movement: [mx, my], cancel, last}) => {
    const isMainlyHorizontal = Math.abs(mx) > Math.abs(my) * 2 // Adjust multiplier based on needed sensitivity

    if (mx > 0) {
      if (isMainlyHorizontal) {
        if (mx > 130) {
          if (last) {
            triggerFunction()
            if (cancel) cancel() // Cancel the drag event to prevent further actions
          }
        } else {
          const newOpacity = 1 - Math.max(0, Math.min(1, mx / 130))
          set({x: down ? mx : 0, opacity: down ? newOpacity : 1, immediate: down ? down : false})
        }
      }
    }
  })

  const triggerFunction = () => {
    goBack()
  }

  return (
    <animated.div
      {...bind()}
      style={{
        width: '100%',
        height: '100vh',
        touchAction: 'pan-y',
        transform: x.interpolate(x => `translate3d(${x}px,0,0)`),
        opacity: opacity,
      }}>
      {children}
    </animated.div>
  )
}

export default Spring
