import axios from 'axios'

class Req {
  constructor() {
    this.axiosInstance = axios.create({})
    this.sessionId = localStorage.getItem('y-sess')
    console.log('load', this.sessionId)
    this.axiosInstance.interceptors.request.use(this.handleRequest, this.handleError)
  }

  update() {
    this.sessionId = localStorage.getItem('y-sess')
    console.log('update', this.sessionId)
  }

  handleRequest = config => {
    if (this.sessionId) {
      config.headers['sess-id'] = this.sessionId
    }
    return config
  }

  async post(url, data, config = {}) {
    try {
      const response = await this.axiosInstance.post(url, data, config)
      return response
    } catch (error) {
      // Optionally handle errors here or just throw to be caught by the caller
      throw error
    }
  }

  async get(url, config = {}) {
    try {
      const response = await this.axiosInstance.get(url, config)
      return response
    } catch (error) {
      // Optionally handle errors here or just throw to be caught by the caller
      throw error
    }
  }
}

export default new Req()
