import {ethers} from 'ethers'
import Req from '../util/Req'

import {deviceCheck, browserCheck} from '../util/helpers'

//images
import xPng from '../imgs/x.png'
import ethLogo from '../imgs/ethLogo.png'
import sol1 from '../imgs/sol1.png'
import leftPng from '../imgs/left.png'
import copyPng from '../imgs/copy.png'
import qrPng from '../imgs/qr.png'
import maticPng from '../imgs/matic2.png'

export const getDevice = () => {
  return async (dispatch, getState) => {
    let device = deviceCheck()
    let browser = ''
    if (device?.includes('Android')) {
      browser = browserCheck('android')
    }
    if (device?.includes('iOS')) {
      browser = browserCheck('ios')
    }

    dispatch({
      type: 'set',
      payload: {device, browser},
    })
  }
}

export const getMe = () => {
  return async (dispatch, getState) => {
    let {user, url} = getState()
    try {
      let res = await Req.get(`${url}/user`)
      console.log('getMe', res.data?.user)
      user = {...user, ...res.data?.user}
      dispatch({type: 'set', payload: {user, loading: false}})
    } catch (err) {
      console.log('getMe err')
      dispatch({type: 'set', payload: {loading: false}})
    }
  }
}

export const preloadImg = () => {
  return async (dispatch, getState) => {
    const solImg = new Image()
    solImg.src = sol1
    const ethImg = new Image()
    ethImg.src = ethLogo
    const xImg = new Image()
    xImg.src = xPng
    const leftImg = new Image()
    leftImg.src = leftPng
    const copyImg = new Image()
    copyImg.src = copyPng
    const qrImg = new Image()
    qrImg.src = qrPng
    const maticImg = new Image()
    maticImg.src = maticPng
  }
}

export const setEnv = (isMobile, screenWidth, screenHeight) => ({
  type: 'set',
  payload: {
    isMobile,
    screenWidth,
    screenHeight,
  },
})

export const setPush = pushKey => ({
  type: 'set',
  payload: {
    pushKey,
  },
})

export const setFlip = flip => ({
  type: 'set',
  payload: {
    flip,
  },
})

export const setSp = sp => ({
  type: 'set',
  payload: {
    sp,
  },
})
