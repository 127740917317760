import React from 'react'
import {useSpring, animated} from 'react-spring'
import {useDrag} from 'react-use-gesture'

let SpringRow = ({children, onSwipe}) => {
  const [{x, opacity}, set] = useSpring(() => ({x: 0, opacity: 1}))

  const bind = useDrag(({down, movement: [mx, my], cancel, last}) => {
    const isMainlyHorizontal = Math.abs(mx) > Math.abs(my) * 2

    if (isMainlyHorizontal) {
      if (mx < 0) {
        const newOpacity = 1 - Math.max(0, Math.min(1, Math.abs(mx) / 130))
        set({x: down ? mx : 0, opacity: down ? newOpacity : 1, immediate: down})

        if (mx < 130 && last) {
          onSwipe()
          if (cancel) cancel()
        }
      }
    }
  })

  return (
    <animated.div
      {...bind()}
      style={{
        width: '100%',
        touchAction: 'pan-y',
        transform: x.to(x => `translate3d(${x}px,0,0)`),
        opacity: opacity,
      }}>
      {children}
    </animated.div>
  )
}

export default SpringRow
