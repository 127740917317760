import {useSelector, useDispatch} from 'react-redux'
import {box, secretbox, randomBytes} from 'tweetnacl'
import secureLocalStorage from 'react-secure-storage'
import {decodeUTF8, encodeUTF8, encodeBase64, decodeBase64} from 'tweetnacl-util'

export function deviceCheck() {
  const userAgent = navigator.userAgent
  if (/Android/i.test(userAgent)) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return 'Android PWA'
    }
    return 'Android'
  }

  if (/iPhone|iPad|iPod/i.test(userAgent)) {
    if (window.matchMedia('(display-mode: standalone)').matches) {
      return 'iOS PWA'
    }
    if (window.navigator.standalone) {
      return 'iOS PWA'
    }
    return 'iOS'
  }

  if (/Windows|Macintosh|Linux/i.test(userAgent)) {
    return 'Desktop'
  }

  return 'Unknown'
}

export function browserCheck(type) {
  const userAgent = navigator.userAgent

  if (type == 'ios') {
    if (/CriOS/i.test(userAgent)) {
      return 'Chrome on iOS'
    } else if (/Safari/i.test(userAgent) && !/Chrome/i.test(userAgent)) {
      return 'Safari on iOS'
    }

    return 'Unknown iOS Browser'
  }

  if (type == 'android') {
    if (/Android/i.test(userAgent)) {
      if (/Chrome/i.test(userAgent)) {
        return 'Chrome on Android'
      } else {
        return 'Other Browser on Android'
      }
    }

    return 'Not an Android Device'
  }
}

export function makeId(length) {
  let result = ''
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charactersLength = characters.length
  let counter = 0
  while (counter < length) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
    counter += 1
  }
  return result
}

export function msToTime(duration) {
  var milliseconds = Math.floor((duration % 1000) / 100),
    seconds = Math.floor((duration / 1000) % 60),
    minutes = Math.floor((duration / (1000 * 60)) % 60),
    hours = Math.floor((duration / (1000 * 60 * 60)) % 24),
    days = Math.floor(duration / (1000 * 60 * 60 * 24))

  return {days, hours, minutes, seconds}
}

export function timeAgo(timeStamp) {
  let now = new Date(),
    secondsPast = (now.getTime() - timeStamp * 1000) / 1000

  if (secondsPast < 60) {
    return parseInt(secondsPast) + 's'
  }
  if (secondsPast < 3600) {
    return parseInt(secondsPast / 60) + 'm'
  }
  if (secondsPast <= 86400) {
    return parseInt(secondsPast / 3600) + 'h'
  }
  if (secondsPast > 86400) {
    let day = timeStamp * 1000
    let date = new Date(day)
    let showMonths = ''
    let months = (now.getFullYear() - date.getFullYear()) * 12 + now.getMonth() - date.getMonth()
    if (months > 1) {
      showMonths = months + 'M '
    }
    return showMonths + (now.getDate() - date.getDate()) + 'd'
  }
}

export function shortMonth(num) {
  const monthNames = ['Jan', 'Feb', 'March', 'April', 'May', 'June', 'July', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  return monthNames[num - 1]
}

export function shortWeekday(num) {
  const monthNames = ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat']
  return monthNames[num]
}

export function encryptSolo(pin, message) {
  // Derive a 32-byte key from the pin.
  const key = new Uint8Array(32)
  encodeUTF8(pin)
    .split('')
    .forEach((char, index) => {
      key[index] = char.charCodeAt(0)
    })

  // Generate a nonce (always generate a new nonce for each encryption!)
  const nonce = randomBytes(secretbox.nonceLength)

  // Encrypt
  const encrypted = secretbox(decodeUTF8(message), nonce, key)

  // The data to be saved: nonce + encrypted message
  const fullBox = new Uint8Array(nonce.length + encrypted.length)
  fullBox.set(nonce)
  fullBox.set(encrypted, nonce.length)

  // Convert to base64 for easier storage
  const boxBase64 = encodeBase64(fullBox)

  return boxBase64
}

export function decryptSolo(pin, boxBase64) {
  // Derive a 32-byte key from the pin.
  const key = new Uint8Array(32)
  encodeUTF8(pin)
    .split('')
    .forEach((char, index) => {
      key[index] = char.charCodeAt(0)
    })

  const fullBoxFromServer = decodeBase64(boxBase64)

  const nonceFromServer = fullBoxFromServer.slice(0, secretbox.nonceLength)
  const encryptedFromServer = fullBoxFromServer.slice(secretbox.nonceLength, fullBoxFromServer.length)

  const decrypted = secretbox.open(encryptedFromServer, nonceFromServer, key)
  if (!decrypted) {
    console.log('error decrypting')
  }

  const decryptedMessage = encodeUTF8(decrypted)
  console.log(decryptedMessage)
  return decryptedMessage
}

export async function setKey(key, chain = 'eth') {
  let saveString = 'soco'
  if (chain == 'sol') {
    saveString = 'socoSol'
  }
  await secureLocalStorage.setItem(saveString, key)
}

export async function getKey(chain = 'eth') {
  let saveString = 'soco'
  if (chain == 'sol') {
    saveString = 'socoSol'
  }
  let key = secureLocalStorage.getItem(saveString)
  await key
  return key
}

/*
export function getKey() {
  let key = secureLocalStorage.getItem('spgaK')
  let keyArr = Object.values(key)
  return keyArr
}

export function newNonce() {
  return randomBytes(box.nonceLength)
}

export function keyPair() {
  const generateKeyPair = () => box.keyPair()
  const pair = generateKeyPair()
  secureLocalStorage.setItem('spgaPub', pair.publicKey)
  secureLocalStorage.setItem('spgaK', pair.secretKey)
  return Array.from(pair.publicKey)
}

export function getPubKey() {
  let pubKey = secureLocalStorage.getItem('spgaPub')
  if (pubKey) {
    pubKey = Object.values(pubKey)
  }
  return pubKey
}

export function soloKey() {
  let generateKey = () => encodeBase64(randomBytes(secretbox.keyLength))
  let soloKey = generateKey()
  secureLocalStorage.setItem('spgaSolo', soloKey)
}

export function getSoloKey() {
  let soloKey = secureLocalStorage.getItem('spgaSolo')
  return soloKey
}

export function decrypt(inKey, text) {
  const messageWithNonceAsUint8Array = decodeBase64(text)
  const nonce = messageWithNonceAsUint8Array.slice(0, box.nonceLength)
  const message = messageWithNonceAsUint8Array.slice(box.nonceLength, text.length)

  const decrypted = box.open.after(message, nonce, inKey)

  let base64DecryptedMessage = null
  if (!decrypted) {
    base64DecryptedMessage = '----------'
  } else {
    base64DecryptedMessage = encodeUTF8(decrypted)
  }

  return base64DecryptedMessage //JSON.parse(base64DecryptedMessage);
}

export function decryptSolo(text, key) {
  const keyUint8Array = decodeBase64(key)
  const messageWithNonceAsUint8Array = decodeBase64(text)
  const nonce = messageWithNonceAsUint8Array.slice(0, secretbox.nonceLength)
  const message = messageWithNonceAsUint8Array.slice(secretbox.nonceLength, text.length)

  const decrypted = secretbox.open(message, nonce, keyUint8Array)

  let base64DecryptedMessage = null
  if (!decrypted) {
    base64DecryptedMessage = '----------'
  } else {
    base64DecryptedMessage = encodeUTF8(decrypted)
  }

  return base64DecryptedMessage //JSON.parse(base64DecryptedMessage);
}

export function encrypt(inKey, text) {
  const nonce = newNonce()
  const messageUint8 = decodeUTF8(text)
  const encrypted = box.after(messageUint8, nonce, inKey)

  const fullMessage = new Uint8Array(nonce.length + encrypted.length)
  fullMessage.set(nonce)
  fullMessage.set(encrypted, nonce.length)

  const base64FullMessage = encodeBase64(fullMessage)
  return base64FullMessage
}

export function encryptSolo(key, text) {
  const keyUint8Array = decodeBase64(key)
  const nonce = newNonce()
  const messageUint8 = decodeUTF8(text)
  const box = secretbox(messageUint8, nonce, keyUint8Array)

  const fullMessage = new Uint8Array(nonce.length + box.length)
  fullMessage.set(nonce)
  fullMessage.set(box, nonce.length)

  const base64FullMessage = encodeBase64(fullMessage)
  return base64FullMessage
}

export function hexToBytes(hex) {
  let bytes = []
  for (let i = 0; i < hex.length - 1; i += 2) {
    bytes.push(parseInt(hex.substr(i, 2), 16))
  }
  return bytes
}
*/

export function hexToInt(hexString) {
  if (hexString.length % 2 !== 0) {
    console.error('Invalid hex string')
    return
  }

  const byteArray = new Uint8Array(hexString.length / 2)
  for (let i = 0; i < hexString.length; i += 2) {
    byteArray[i / 2] = parseInt(hexString.slice(i, i + 2), 16)
  }
  return byteArray
}

export function last5() {
  const today = new Date()
  const dayNames = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

  const getTimestampStartOfDay = date => {
    return new Date(date.getFullYear(), date.getMonth(), date.getDate()).getTime()
  }

  let daysArray = []

  for (let i = 0; i < 5; i++) {
    const dayDate = new Date(today)
    dayDate.setDate(today.getDate() - i)

    const dayName = dayNames[dayDate.getDay()]
    const startTimestamp = getTimestampStartOfDay(dayDate)
    const endTimestamp = startTimestamp + 86400000 - 1

    daysArray.push({day: dayName, start: startTimestamp, end: endTimestamp})
  }

  return daysArray
}
